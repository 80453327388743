@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.user-home{
    margin-top: 2vh;
}

.user-home .sub-head{
    color: #0d6efd;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 3rem;
}

.user-home .home-analytic{
    margin-top: 4vh;
}
.teacherHeader{
    text-align: center;
    margin-bottom: 3rem;
}
.teacher-cards{
    margin-bottom: 3rem;
}
.user-home .heading{
    color: #201f1f;
    font-family: 'Roboto Slab', serif;
}

.user-home .user-home-cards{
    margin-top: 4vh;
    margin-bottom: 4vh;
}

.user-home .user-home-cards .card{
    margin-top: 6vh;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);   
    cursor: pointer;
    border-radius: 12px;
}

.user-home .user-home-cards .card .card-icon{
    position: absolute;
    right: -1rem;
    top: -1.5rem;
    font-size: 5.5rem;
    border: 1px solid #0dcaf0;
    background-color: #0d6efd;
    border-radius: 50%;
    padding: 0.7rem;
    color: white;
}

.user-home .user-home-cards .card .card-title{
    color: #0d6efd;;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
}

.user-home .user-home-cards .card .card-text{
    margin-top: 4vh;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 17px;
}

.user-home .user-home-cards .card button{
    background-color: #0d6efd;
    width: 7rem;
    border-radius: 7px;
    font-family: 'Roboto Slab', serif;
    font-size: 1rem;
    transition: transform 0.3s ease-out;
}

.user-home .user-home-cards .card:hover{
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transform: scale(1.02);
}