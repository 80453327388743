@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

.login .login-heading h1{
    margin-top: 3rem;
    margin-bottom: 3rem;
    color: #0d6efd;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-size: 3rem;
}

.login .card-login{
    width: 48rem;
    margin-bottom: 3rem;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);   
    border-radius: 12px;
}

.login .card-login .card-title{
    color: #000;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    margin-top: 1rem;
    font-size: 2.5rem;
    text-decoration: underline;
}

.login .card-login .login-form{
    padding-left: 3rem;
    padding-right: 3rem;
}

.login .card-login .submit-button{
    margin-top: 2.5vh;
    padding: 0.5rem 0.5rem;
    background-color: #0d6efd;
    width: 9rem;
}

.login .card-login .error-message{
    color: #ff0000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}