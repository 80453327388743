.footer {
    padding-top: 5vh;
    background-color: #0d6efd;
    position: relative;
    bottom: 0;
    width: 100%;
    color: lightgrey;
}
.footer .colum{
    padding-left: 40px;
}

.footer .footer-logo img{
    width:100%;
    height:auto;
}
.footer .footer-text h2{
    font-size: 1.8rem;
}
.footer .footer-text p{
    font-size: 18px;
    font-weight: 400;
    color: #fff;
}

.footer .footer-text p a{
    color: #000;
    font-weight: 600;
    
}

.footer .footer-contact {
    padding-left: 0px;
}

.footer .footer-contact a{
    text-decoration: none;
    color: #fff;
    padding: 5px;
    font-size: 20px;
}

.footer .footer-contact .contact-icon{
    color: #000;
    font-size: 1.6rem;
}

.footer .footer-contact p{
    color: #fff;
    font-size: 20px;
}

/* footer-icon on hover rotate animation */
.footer .footer-text .footer-icon{
    padding-right: 10px;
    color:white;
    transition: 0.4s;

}

.footer .footer-text .footer-icon:hover{
    transform: translateY(-6px);

}