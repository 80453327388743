@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

.tracks{
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.tracks .main-heading h2{
    text-align: center;
    color: #000;
    /* font-family: 'Roboto Slab', serif; */
    color: #0d6efd;
    font-size: 2.7rem;
}

.tracks .tracks-card{
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.tracks .tracks-card .card{
    width: 28rem;
    margin-top: 6vh;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);   
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.3s;
}

.tracks .tracks-card .card:hover{
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);   
    transform: translateY(-0.5rem);
}

.tracks .tracks-card .card .card-img-top{
    height: 12rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width:auto;
    object-fit: cover;
    max-width: 100%;
}

.tracks .tracks-card .card .card-title{
    color: #0d6efd;
    font-family: 'Roboto Slab', serif;
    font-size: 2.7rem;
}

.tracks .tracks-card .card .card-text{
    margin-top: 2.5vh;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
}

.tracks .tracks-card .card button{
    margin-top: 2.5vh;
    padding: 0.5rem 0.5rem;
    background-color: #0d6efd;
    width: 7rem;
    font-size: 1.1rem;
}