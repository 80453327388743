@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.analytics{
    margin-top: 2vh;
}

.analytics .sub-head{
    color: #0d6efd;;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
}

.analytics .analytics-main{
    margin-top: 4vh;
}

.analytics .analytics-main .bar-chart{
    margin-top: 2vh;
    margin-bottom: 3vh;
}

.analytics .analytics-main .bar-chart .form-label{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #0f0f0f;
}

.analytics .analytics-main .bar-chart .form-select{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    color: #0f0f0f;
    border-radius: 8px;
    padding: 0.2rem 0.5rem;
    margin-left: 0.5vw;
    margin-bottom: 2vh;
    width: 10rem;
}

.analytics .analytics-main .tableau{
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.analytics .analytics-main .tableau .main-heading{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    color: #0f0f0f;
    font-size: 35px;
    margin-top: 4vh;
    margin-bottom: 3vh;
}