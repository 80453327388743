/* navbar fixed at top */
.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    box-shadow: 0 1px 0 rgba(0,0,0,.05);
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
}
    
.nav-logo{
    width: 35%;
    height:auto;
}

.navbar-links{
    display: inline-block;
    justify-content: space-around;
}

.navbar-links span{
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.navbar-links span:hover{
    cursor: pointer;
    color: #0d6efd;
}

.navbar-links a:hover{
    color: #0d6efd;
    text-decoration: none;
}

.navbar-links .active{
    color: #0d6efd;
    text-decoration: none;
}

.buttons button{
    color: #fff;
    background-color: #0d6efd;
    border: 2px solid #4139aa;
    border-radius: 15px;
    width: 120px;
    box-shadow: 0 1px 0 rgba(0,0,0,.05);
    font-size: 18px;
    font-weight: 600;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    /* hover scale animation */
    transition: all .2s ease-in-out;
}

/* add hover scale animation */
.buttons button:hover{
    transform: scale(1.1);
}

.login-container{
    padding-top: 20vh;
    background-color:#e1f0fb;
    padding-bottom: 10vh;
}

.login-container .auth-image{
    /* aling image DIV to center */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.login-container .left-logo{
    width: 550px;
}

.login-container .card-top{
    position: relative;
}

.login-container .register-button{
    border: none;
    width: 15rem;
    height: 5rem;
    color: #000;
    background-color: #e1f0fb;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 30px;
}

.login-container .login-button{
    border: none;
    width: 15rem;
    height: 5rem;
    color: #0d6efd;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 30px;
}


.login-container .card-top .card-icon{
    position: absolute;
    top: 0;
    left: 13rem;
}

/* set .register-container .card-icon class responsive*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login-container{
        padding-top: 20vh;
        background-color:#e1f0fb;
        padding-bottom: 10vh;
        margin-top: 10vh;
    }
    .login-container .card-top .card-icon{
        left: 12.5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .login-container{
        padding-top: 20vh;
        background-color:#e1f0fb;
        padding-bottom: 10vh;
        margin-top: 10vh;
    }
    .login-container .card-top .card-icon{
        left: 13rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .login-container{
        padding-top: 20vh;
        background-color:#e1f0fb;
        padding-bottom: 10vh;
        margin-top: 10vh;
    }
    .login-container .card-top .card-icon{
        left: 12,4rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .login-container .card-top .card-icon{
        left: 13rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .login-container .card-top .card-icon{
        left: 13rem;
    }
}

.login-container .card-top .card-icon img{
    width: 80px;
}

.login-container .login-card{
    margin-top: -1px;
    border-top: none;
    border-top-left-radius: 0%;
}

.login-card .card-title{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

.login-container .login-card .login-form{
    padding-left: 20px;
    padding-right: 10px;
}

.login-container .login-card .login-form .submit-button{
    border: none;
    color: #fff;
    border-radius: 15px;
    background-color: #0d6efd;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size:18px;
    padding: 6px;
    width: 10rem;
}

.login-card .login-form .error-message{
    color: #ff0000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}