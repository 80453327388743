.numbers{
    padding-top: 10vh;
    background-color: #e1f0fb;
    padding-bottom: 10vh;
    /* margin-bottom: 10vh; */
    text-align: center;
}

.numbers .number-icon{
    height: 80px;
    padding: 10px;
    color: #0d056c;
}

.numbers .number-text{
    font-size: 26px;
    font-weight: 500;
    color: #0d056c;
}