.category{
    padding-top: 7vh;
    padding-bottom: 10vh;
}

.category .category-heading{
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10vh;
}

.category .category-card{
    box-shadow: 0px 0px 1px 2px #0dcaf0;
    padding: 20px 20px 20px 20px;
    transition: 0.3s;
    margin-bottom: 5vh;
    border:none;
    background-color: #e1f0fb;
}

.category .category-card:hover{
    box-shadow: 0px 0px 10px #0d6efd;
    transform: scale(1.07);
}

.category .category-card .category-card-icon{
    color: #0d6efd;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
}

.category .category-card .card-title{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #0d6efd;
}

.category .category-card .card-text{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #000;
}

.category .category-card-button{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 140px;
    background-color: #0d6efd;
    border: 0px solid #000;
    border-radius: 15px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    padding: 8px;
}