@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.practice{
    margin-top: 2vh;
}

.practice .editor{
    margin-top: 4vh;
    margin-bottom: 8vh;
}

.practice .editor h3{
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
}

.practice .editor .form-label{
    margin-top: 3vh;
    color: #0d6efd;;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.practice .editor .row{
    margin-top: 3vh;
    margin-bottom: 5vh;
}

.practice .editor .form-select{
    width: 10rem;
}

.practice .editor .run-button{
    background-color: #0d6efd;;
    width: 10rem;
    padding: 0.5rem 1.2rem;
    margin-top: 6vh;
    margin-bottom: 5vh;
    border-radius: 8px;
}

.practice .editor .run-button:hover{
    background-color: #0d6efd;
}

.practice .editor .input{
    width: 20rem;
}

.practice .editor .output-window h3{
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
}

.practice .editor .output-window .output{
    margin-top: 6vh;
    margin-bottom: 2vh;
    background-color: #ffffff;
    border-radius: 8px;
    width: 25rem;
    min-height: 20vh;
    padding: 1vh 1.5rem;
    white-space: pre-line;
}

.practice .editor .output-window .time-memory .time{
    color: #000;
    padding-bottom: 1rem;
    font-family: 'Roboto Slab', serif;
    font-size: 1.1rem;
}

.practice .editor .output-window .time-memory .memory{
    color: #000;
    padding-bottom: 1rem;
    font-family: 'Roboto Slab', serif;
    font-size: 1.1rem;
}

.practice .editor .error-window h3{
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: 'Roboto Slab', serif;
    font-size: 1.6rem;
    color: #ff0000;
}

.practice .editor .error-window .error{
    margin-top: 6vh;
    margin-bottom: 6vh;
    background-color: #ffffff;
    border-radius: 8px;
    width: 25rem;
    min-height: 20vh;
    padding: 1vh 1.5rem;
    color: #e74a4a;
}